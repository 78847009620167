import request from '@/http/request'
import wayCode from "@/utils/wayCode";
import channelUserIdUtil from "@/utils/channelUserId";
import config from '@/config'

/** 获取url **/
export function getRedirectUrl() {
    return request.request({
        url: '/api/cashier/redirectUrl',
        method: 'POST',
        data: {wayCode: wayCode.getPayWay().wayCode, token: config.cacheToken}
    })
}

/** 获取url **/
export function getChannelUserId(redirectData) {
    return request.request({
        url: '/api/cashier/channelUserId',
        method: 'POST',
        data: Object.assign({
            wayCode: wayCode.getPayWay().wayCode,
            token: '145600a332a7faf27ac5674c7c6deded5f851fafafe0fb6bfabb5ca83ac17bc1'
        }, redirectData)
    })
}

export function getNewChannelUserId(redirectData) {
    return request.request({
        url: '/api/cashier/newChannelUserId',
        method: 'POST',
        data: Object.assign({wayCode: wayCode.getPayWay().wayCode}, redirectData)
    })
}

/** 调起支付接口, 获取支付数据包 **/
export function getPayPackage(amount) {
    return request.request({
        url: '/api/cashier/pay',
        method: 'POST',
        data: {
            wayCode: wayCode.getPayWay().wayCode,
            token: config.cacheToken,
            amount: amount,
            channelUserId: channelUserIdUtil.getChannelUserId()
        }
    })
}

export function getNewPayPackage(ifCode,money, mchOrderNo, remark, channelUserId) {
    return request.request({
        url: '/api/cashier/newPay',
        method: 'POST',
        data: {
            ifCode:ifCode,
            wayCode: wayCode.getPayWay().wayCode,
            amount: money,
            mchOrderNo: mchOrderNo,
            remark: remark,
            channelUserId: channelUserId || undefined,
        }
    })
}

/** 调起支付接口, 获取订单信息 **/
export function getPayOrderInfo() {
    return request.request({
        url: '/api/cashier/payOrderInfo',
        method: 'POST',
        data: {
            token: config.cacheToken
        }
    })
}

/** 取消订单 **/
export function cancelPay() {
    return request.request({
        url: '/api/cashier/cancelPay',
        method: 'POST',
        data: {
            token: config.cacheToken
        }
    })
}
